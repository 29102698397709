import { RequestModel, EventFunnelModel, ProductFunnelModel } from "../models";
import { IHttpResponseBdB } from "./i-http-response-bdb.interface";

export abstract class IHttpClientBdB {

    abstract requestPost(request: RequestModel): IHttpResponseBdB;

    abstract requestGet(request: RequestModel): IHttpResponseBdB;

    abstract requestPut(request: RequestModel): IHttpResponseBdB;

    abstract requestGetRetryWhen(request: RequestModel): IHttpResponseBdB;

    abstract getMappingFunnel(info: ProductFunnelModel):  EventFunnelModel;

}
