import { Injectable, Optional } from '@angular/core';
import { EnvironmentConfigLiteral } from './lazy-environment-loader.service';
import { ENVIRONMENT } from '../enums';

let nextId = 1;

export class EnvironmentConfig {
  env: ENVIRONMENT;
}

export class EnvironmentServiceClass {

  config: EnvironmentConfig;
  private _environment: ENVIRONMENT;

  constructor(config: EnvironmentConfig) {
    this.config = config;
    this._environment = config.env;
  }

  get environment(): ENVIRONMENT {
    console.log('this._environment', this._environment);
    return this._environment;
  }

}

export function EnvironmentServiceFactory(config) {
  return new EnvironmentServiceClass(config);
}

@Injectable()
export class EnvironmentService {

  id = nextId++;
  private _environment: ENVIRONMENT;

  constructor(@Optional() config: EnvironmentConfig) {
    console.log('EnvironmentService (config)', config);
    if (config) {
      this._environment = config.env;
    }
  }

  get environment(): ENVIRONMENT {
    console.log('EnvironmentService (instances)', this.id);
    return this._environment;
  }
}
