import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FunnelService, RequestService, DeviceDetectorService } from './services/index';
import { PropertiesHttpClientService } from './services/properties-http-client.service';


@NgModule({
  imports: [
    CommonModule
  ]
})

export class BdbHttpClientModule {

  static forRoot(): ModuleWithProviders {

    return {
      ngModule: BdbHttpClientModule,
      providers: [
        DeviceDetectorService,
        FunnelService,
        PropertiesHttpClientService,
        RequestService
      ]
    }

  }

}
