import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DeviceInfoModel } from '../models/device-info.model';
import { DeviceDetectorService } from './device-detector.service';
import { EventFunnelModel, RequestFunnelModel } from 'bdb-common-collections';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ENDPOINT } from '../enums/endpoint';
import { PropertiesHttpClientService } from './properties-http-client.service';

@Injectable()
export class FunnelService {

  /**@description Informacion del dispositivo */
  deviceInfo: DeviceInfoModel = null;
  /**@description Servicio de deteccion de caracteristicas del dispositivo */
  deviceDetector: DeviceDetectorService;

  constructor(private deviceService: DeviceDetectorService, private http: HttpClient, 
              private propertiesService: PropertiesHttpClientService) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceDetector = this.deviceService;
  }

  public sendFunnelEvent(data: EventFunnelModel) {

    const baseUrl = this.propertiesService.getEndpoint(ENDPOINT.Funnel);
    console.log('Funnel BaseURL: ', baseUrl);

    if (baseUrl) {

      data.browser = this.deviceInfo.browser;

      data.device = this.deviceDetector.isMobile() ? 'Mobile' : this.deviceDetector.isDesktop() ? 'Desktop' : this.deviceDetector.isTablet() ? 'Tablet' : 'NA';
      data.privateIp = this.deviceInfo.private_ip;
      data.publicIp = this.deviceInfo.public_ip;

      const dataBase64 = btoa(JSON.stringify(data));

      const request: RequestFunnelModel = {
        messageType: 'bdb-funnel',
        messageContent: dataBase64
      };

      const h: HttpHeaders = new HttpHeaders();
      h.append('Content-Type', 'application/json;');
      const httpOptions = { headers: h };
      httpOptions['observe'] = 'response';

      this.http.post(baseUrl, request, httpOptions)
        .pipe(
          map((response: any) => {
            return response;
          }),
          catchError((error: Response) => {
            // console.log('Error reportando evento: ', data);
            // TODO: Implementar persistencia local en caso de fallo y logica de reintentos.
            // return Observable.throw(error);
            return throwError(error);
          })
        )
        .subscribe();

    }

  }
}
