import { Dictionary } from "./dictionary";
import { ICON } from "../enums";

// @dynamic
export class ImageFactory {

    private static dicSVG: Dictionary<string> = null;

    constructor() { }

    public static getSource(icon: ICON): string {
        let sReturn = '';
        const dic = ImageFactory.getInstance();

        if (dic.containsKey(icon)) {
            sReturn = dic.item(icon);
        }

        return sReturn;
    }

    private static getInstance(): Dictionary<string> {
        if (ImageFactory.dicSVG == null) {
            ImageFactory.dicSVG = new Dictionary<string>();
            ImageFactory.loadInfoToDic();
        }

        return ImageFactory.dicSVG;
    }

    private static loadInfoToDic() {
        ImageFactory.dicSVG.add(ICON.flexiAhorroIcon, `<svg xmlns="http://www.w3.org/2000/svg" width="160" height="91" viewBox="0 0 160 87">` +
            `<g fill="none" fill-rule="evenodd"><path fill="#C4D4E3" fill-opacity=".2" d="M34.745 57.601l11.508-7.762c1.386-.935 ` +
            `3.294-.53 4.262.906.968 1.435.629 3.355-.757 4.29l-6.193 4.177c-1.507 1.017-1.875 3.106-.822 4.666 1.05 1.557 3.119 2 ` +
            `4.625.991L69.17 50.264c.621-.416 1.481-.268 1.952.347l2.887 4.28c.515.676.374 1.634-.309 2.089L39.452 79.799c-1.683 1.122-2.1 3.447-.928 ` +
            `5.184 1.167 1.73 3.467 2.219 5.138 1.092l6.643-4.481c2.333-1.573 5.544-.89 7.174 1.525 1.931 2.259 4.003 2.64 6.216 1.143L123.61 43.72c2.528-1.708 ` +
            `3.195-3.777 2.001-6.207-1.761-2.611-4.47-2.237-7.002-.553l-8.1 ` +
            `5.389c-.65.432-1.539.24-1.99-.43l-4.014-5.95c-.454-.673-.295-1.574.354-2.012l20.53-13.848c1.704-1.149 2.12-3.511.93-5.275-1.19-1.765-3.536-2.264-5.24-1.115l-10.228 ` +
            `6.9c-1.853-2.747-5.504-3.523-8.155-1.735l-5.352 3.61c-.65.439-1.544.248-1.998-.424l-1.657-2.457c-.454-.673-.295-1.573.355-2.012l17.023-11.48c1.407-.95 ` +
            `1.75-2.9.768-4.357-.983-1.457-2.92-1.869-4.327-.92l-76.322 51.48c-1.406.949-1.75 2.899-.767 4.356.982 1.457 2.92 1.869 4.326.92z"/>` +
            `<g transform="translate(37 13)">` +
            `<rect width="82.852" height="31" x="3.148" fill="#0040A8" fill-rule="nonzero" rx="6"/>` +
            `<path fill="#002C76" fill-rule="nonzero" d="M25.909 20.938h33.166a2 2 0 0 1 1.98 1.724l5.42 38.847a4 4 0 0 1-3.96 4.553H24.277a4 4 0 0 ` +
            `1-3.983-4.37l3.623-38.939a2 2 0 0 1 1.991-1.815z"/>` +
            `<path stroke="#FFF" stroke-width="2" d="M23.612 19.216l-5.25 39.215c.003 2.395 1.447 4.197 ` +
            `4.198 4.197h38.732c2.836 0 4.198-1.806 4.198-4.197l-5.247-39.777"/>` +
            `<path stroke="#FFF" d="M29.781 18.654l-2.098 42.973"/>` +
            `<path fill="#FDC130" d="M53.07 45.123h4.906a2 2 0 0 1 1.994 1.842l.732 9.233a2 2 0 0 1-1.993 2.158h-6.371a2 2 0 0 ` +
            `1-1.994-2.158l.732-9.233a2 2 0 0 1 1.994-1.842z"/>` +
            `<path stroke="#FFF" stroke-width="2" d="M21.76 33.736H4.428A4.21 4.21 0 0 1 .23 29.538V8.36a4.21 4.21 0 0 1 4.197-4.197h72.998a4.21 4.21 0 0 1 ` +
            `4.197 4.197v21.178a4.21 4.21 0 0 1-4.197 4.198H62.092"/>` +
            `<path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.957 18.949h59.938"/>` +
            `<path stroke="#FDC130" stroke-linecap="round" stroke-width="2" d="M60.602 11.603h11.343"/>` +
            `</g>` +
            `<path fill="#FFF" d="M2.571 20.33H1.43c-.79 0-1.429.628-1.429 1.4 0 .774.64 1.401 1.429 1.401H2.57v1.12c0 .774.64 1.401 ` +
            `1.429 1.401s1.429-.627 1.429-1.4v-1.12H6.57C7.36 23.131 8 22.503 8 21.73s-.64-1.4-1.429-1.4H5.43V19.21c0-.773-.64-1.4-1.429-1.4-.79 ` +
            `0-1.429.627-1.429 1.4v1.12z"/>` +
            `<path fill="#7AD468" d="M154 4.775c0 1.597 1.326 2.892 2.96 2.892 1.636 0 2.962-1.295 2.962-2.892 0-1.598-1.326-2.893-2.961-2.893-1.635 ` +
            `0-2.961 1.295-2.961 2.893z"/>` +
            `<path fill="#FDC130" d="M130 82.579c0 1.118.928 2.024 2.073 2.024 1.144 0 2.072-.906 2.072-2.024 0-1.118-.928-2.025-2.072-2.025-1.145 ` +
            `0-2.073.907-2.073 2.025z"/>` +
            `</g>` +
            `</svg>`);
        ImageFactory.dicSVG.add(ICON.libreAhorroIcon, `<svg xmlns="http://www.w3.org/2000/svg" width="160" height="91" viewBox="0 0 160 91">` +
            `<g fill="none" fill-rule="evenodd">` +
            `<path fill="#C4D4E3" fill-opacity=".2" d="M30.026 58.158l11.649-7.857c1.402-.946 3.324-.55 4.29.884.968 1.434.615 3.363-.788 ` +
            `4.309l-6.269 4.228c-1.525 1.029-1.908 3.127-.856 4.687 1.05 1.556 3.132 1.988 4.657.966l22.066-14.784a1.461 1.461 0 0 1 1.966.337l2.885 ` +
            `4.277c.516.674.367 1.637-.324 2.097l-34.663 23.1c-1.704 1.136-2.138 3.472-.967 5.207 1.166 1.73 3.483 2.206 5.173 1.065l6.725-4.536c2.36-1.592 ` +
            `5.595-.926 7.223 1.488 1.934 2.255 4.02 2.624 6.26 1.11L119.701 43.7c2.558-1.728 3.243-3.808 2.054-6.24-1.76-2.608-4.494-2.217-7.056-.512l-8.198 ` +
            `5.455c-.658.438-1.553.25-2.004-.42l-4.012-5.946c-.453-.672-.288-1.576.37-2.02L121.635 20c1.725-1.163 ` +
            `2.158-3.536.97-5.299-1.19-1.763-3.552-2.25-5.277-1.087l-10.353 6.984c-1.851-2.744-5.527-3.502-8.211-1.691l-5.418 ` +
            `3.654c-.657.443-1.558.258-2.01-.414l-1.657-2.455c-.453-.672-.288-1.577.37-2.02l17.23-11.622c1.425-.96 ` +
            `1.783-2.92.8-4.375-.981-1.457-2.931-1.858-4.356-.898L26.47 52.886c-1.424.96-1.783 2.919-.8 4.375.982 1.456 2.932 1.858 4.356.897z"/>` +
            `<g transform="translate(35 19.549)">` +
            `<ellipse cx="43.81" cy="31.237" fill="#0040A8" rx="35.845" ry="26.775"/>` +
            `<path fill="#FFF" fill-rule="nonzero" d="M24.75 16.85c0 1.116-.925 2.023-2.063 2.023-1.136 ` +
            `0-2.062-.907-2.062-2.023 0-1.115.926-2.022 2.063-2.022s2.062.907 2.062 2.022z"/>` +
            `<path fill="#002C76" d="M12.517 41.241c5.728 2.748 12.575 4.346 19.93 4.346 20.128 0 36.444-11.962 36.444-26.717 0-5.306-2.11-10.25-5.749-14.408 9.944 ` +
            `4.771 16.513 13.009 16.513 22.371 0 14.755-16.316 26.717-36.443 26.717-12.89 0-24.215-4.906-30.695-12.309z"/>` +
            `<path fill="#FFF" d="M33.81 5.578c-.56 0-.81.25-.81.558 0 .308.25.558.81.558H50.6c.559 0 .607-.25.607-.558 0-.308-.048-.558-.607-.558H33.81z"/>` +
            `<path stroke="#FFF" stroke-width="2" d="M42.33 52.434c18.177 0 ` +
            `33.217-9.508 35.14-22.637.038-.261.048-1.925.041-3.42-.005-1.205-.021-2.302-.04-2.471C75.894 ` +
            `10.509 60.763 0 42.328 0 26.532 0 13.16 7.718 8.666 18.357H2.98c-3.973 0-3.973 16.52 0 16.52h5.686C13.48 45.101 26.93 52.434 42.33 52.434z"/>` +
            `<path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M77.568 27.082c3.736.428 6.629-.761 ` +
            `8.679-3.568 2.124-2.908.286-5.59-1.644-5.512-.863.034-3.415.5-2.864 3.82.558 3.356 10.234 5.132 10.234-1.832"/>` +
            `<path fill="#FDC130" d="M47.744 30.427c0 1.27-.354 2.315-1.063 3.134-.71.819-1.724 ` +
            `1.352-3.044 1.6v1.869h-2.274v-1.75c-.399-.024-.794-.064-1.185-.12a27.157 ` +
            `27.157 0 0 1-3.044-.66v-2.168c1.695.384 3.121.575 4.278.575 1.932 0 2.897-.679 ` +
            `2.897-2.037 0-.44-.122-.813-.366-1.12-.245-.308-.656-.606-1.235-.893l-2.53-1.306c-1.01-.536-1.742-1.135-2.195-1.798-.452-.663-.678-1.462-.678-2.397 ` +
            `0-.823.155-1.546.465-2.17a3.814 3.814 0 0 1 1.387-1.527c.615-.396 ` +
            `1.35-.653 2.206-.773v-1.834h2.274v1.762c.799.056 1.984.292 3.557.707v2.17c-1.442-.4-2.754-.6-3.936-.6-1.72 ` +
            `0-2.58.583-2.58 1.75 0 .431.135.79.404 1.078.27.288.787.624 1.553 1.007l2.127 1.055c1.043.511 1.8 1.118 2.273 1.821.473.703.71 1.578.71 2.625z"/>` +
            `<path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M68.14 50.203l1.003 3.445c.15.83-.184 1.335-1.003 ` +
            `1.516-.818.18-2.567.634-5.247 1.359-1.078.245-1.749-.067-2.012-.934-.264-.867-.454-1.453-.57-1.756M20.382 50.203l-1.002 3.445c-.15.83.184 1.335 ` +
            `1.002 1.516.818.18 2.568.634 5.248 1.359 1.077.245 1.748-.067 2.012-.934.263-.867.453-1.453.57-1.756"/>` +
            `</g>` +
            `<path fill="#7AD468" d="M151.807 16.713v1.653h-1.692c-1.168 0-2.115.925-2.115 2.065 0 1.142.947 2.066 2.115 2.066h1.692v1.653c0 1.141.947 2.066 ` +
            `2.115 2.066 1.167 0 2.115-.925 2.115-2.066v-1.653h1.692c1.167 0 2.115-.924 2.115-2.066 ` +
            `0-1.14-.948-2.065-2.115-2.065h-1.692v-1.653c0-1.14-.948-2.066-2.115-2.066-1.168 ` +
            `0-2.115.926-2.115 2.066z"/>` +
            `<path fill="#FDC130" d="M122 88.127c0 1.598 1.326 2.893 2.96 2.893 1.636 0 2.962-1.295 2.962-2.893 0-1.597-1.326-2.892-2.961-2.892-1.635 ` +
            `0-2.961 1.295-2.961 2.892z"/>` +
            `<path fill="#FFF" d="M0 44.123c0 1.118.928 2.024 2.073 2.024 1.144 0 2.072-.906 2.072-2.024 0-1.119-.928-2.025-2.072-2.025-1.145 0-2.073.906-2.073 2.025z"/>` +
            `</g>` +
            `</svg>`);
        ImageFactory.dicSVG.add(ICON.uncheckedAcsMobile, `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">` +
            `<rect width="23" height="23" x=".5" y=".5" fill="none" fill-rule="evenodd" stroke="#B0B0B0" rx="6" />` +
            `</svg>`);
        ImageFactory.dicSVG.add(ICON.checkedAcsMobile, `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">` +
            `<g fill="none" fill-rule="evenodd">` +
            `<rect width="24" height="24" fill="#7AD468" rx="6"/>` +
            `<path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7.5 12l3 3 6-6"/>` +
            `</g>` +
            `</svg>`);
        ImageFactory.dicSVG.add(ICON.uncheckedAcs, `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">` +
            `<rect width="31" height="31" x=".5" y=".5" fill="#FFF" fill-rule="evenodd" stroke="#CCC" rx="6"/>` +
            `</svg>`);
        ImageFactory.dicSVG.add(ICON.checkedAcs, `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">` +
            `<g fill="none" fill-rule="evenodd">` +
            `<rect width="32" height="32" fill="#7AD468" rx="6"/>` +
            `<path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 16l4 4 8-8"/>` +
            `</g>` +
            `</svg>`);
        ImageFactory.dicSVG.add(ICON.libreAhorroIconAcsMobile, `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 38 24">` +
            `<g fill="none" fill-rule="evenodd" transform="translate(1 1.21)">` +
            `<ellipse cx="17.143" cy="11.974" fill="#0040A8" rx="14.026" ry="10.264" />` +
            `<path fill="#FFF" fill-rule="nonzero" d="M9.685 6.46c0 .427-.362.774-.807.774-.445 ` +
            `0-.807-.347-.807-.775 0-.427.362-.775.807-.775.445 0 .807.348.807.775z" />` +
            `<path fill="#002C76" d="M4.898 15.81c2.241 1.052 4.92 1.665 7.799 1.665 7.876 0 ` +
            `14.26-4.585 14.26-10.241 0-2.034-.825-3.93-2.25-5.523 3.892 1.828 6.462 4.986 6.462 8.575 ` +
            `0 5.656-6.384 10.241-14.26 10.241-5.044 0-9.476-1.88-12.01-4.718z" />` +
            `<path fill="#FFF" d="M13.23 2.138c-.219 0-.317.096-.317.214s.098.214.317.214h6.57c.219 ` +
            `0 .237-.096.237-.214s-.018-.214-.237-.214h-6.57z" />` +
            `<path stroke="#FFF" stroke-width=".783" d="M16.564 20.1c7.113 0 12.998-3.645 ` +
            `13.75-8.678.015-.1.02-.738.017-1.311a21.645 21.645 0 0 0-.017-.947C29.698 4.028 23.777 ` +
            `0 16.564 0 10.382 0 5.149 2.958 3.39 7.037H1.166c-1.555 0-1.555 6.333 0 6.333H3.39c1.883 3.919 7.147 6.73 13.173 6.73z" />` +
            `<path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width=".783" ` +
            `d="M30.353 10.381c1.462.164 2.594-.291 3.396-1.367.83-1.115.112-2.143-.643-2.113-.338.013-1.337.192-1.121 ` +
            `1.464.218 1.287 4.004 1.967 4.004-.702" />` +
            `<path fill="#FDC130" d="M18.683 11.664c0 .487-.14.887-.417 1.2-.277.315-.674.52-1.19.614v.717h-.89v-.67a5.797 ` +
            `5.797 0 0 1-.464-.047 10.81 10.81 0 0 1-1.191-.253v-.831c.663.147 1.221.22 1.674.22.756 0 1.133-.26 ` +
            `1.133-.78a.66.66 0 0 0-.143-.43c-.096-.118-.257-.232-.483-.342l-.99-.501c-.396-.205-.682-.435-.859-.689-.177-.254-.265-.56-.265-.919 ` +
            `0-.315.06-.592.181-.831.122-.24.303-.434.543-.586.241-.152.529-.25.864-.296v-.703h.89v.675c.312.022.776.112 1.391.271v.832a5.93 ` +
            `5.93 0 0 0-1.54-.23c-.673 0-1.01.223-1.01.67 0 ` +
            `.166.053.304.159.414.105.11.307.239.607.386l.832.404c.409.196.705.429.89.699.185.269.278.604.278 1.006z" />` +
            `<path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width=".783" ` +
            `d="M26.664 19.244l.392 1.321c.059.318-.072.512-.392.581-.32.07-1.005.243-2.054.521-.421.094-.684-.025-.787-.358a11.777 ` +
            `11.777 0 0 0-.223-.673M7.976 19.244l-.393 1.321c-.058.318.072.512.393.581.32.07 1.004.243 ` +
            `2.053.521.422.094.684-.025.787-.358.103-.332.178-.557.224-.673" />` +
            `</g>` +
            `</svg>`);
        ImageFactory.dicSVG.add(ICON.flexiAhorroIconAcsMobile, `<svg xmlns="http://www.w3.org/2000/svg" width="37" height="28" viewBox="0 0 37 28">` +
            `<g fill="none" fill-rule="evenodd" transform="translate(1)">` +
            `<rect width="34.682" height="12.955" x="1.318" fill="#0040A8" fill-rule="nonzero" rx="3"/>` +
            `<path fill="#002C76" fill-rule="nonzero" d="M10.994 8.75h13.593a1 1 0 0 1 .99.862l2.198 ` +
            `15.719a2 2 0 0 1-1.981 2.277H10.521a2 2 0 0 1-1.992-2.186L10 9.658a1 1 0 0 1 .995-.908z"/>` +
            `<path stroke="#FFF" d="M9.884 8.03L7.686 24.42c.002 1 .606 1.754 1.758 1.754h16.213c1.187 ` +
            `0 1.757-.755 1.757-1.754L25.218 7.796"/>` +
            `<path stroke="#FFF" stroke-width=".5" d="M12.467 7.796l-.879 17.959"/>` +
            `<path fill="#FDC130" d="M22.366 18.858h1.753a1 1 0 0 1 .997.92l.28 3.53a1 1 0 0 1-.997 ` +
            `1.08h-2.314a1 1 0 0 1-.997-1.08l.28-3.53a1 1 0 0 1 .998-.92z"/>` +
            `<path stroke="#FFF" d="M9.11 14.098H1.852a1.76 1.76 0 0 1-1.757-1.754v-8.85c0-.965.79-1.754 ` +
            `1.757-1.754H32.41c.967 0 1.757.79 1.757 1.754v8.85a1.76 1.76 0 0 1-1.757 1.754h-6.418"/>` +
            `<path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" d="M4.586 7.919h25.091"/>` +
            `<path stroke="#FDC130" stroke-linecap="round" d="M25.368 4.849h4.748"/>` +
            `</g>` +
            `</svg>`);
        ImageFactory.dicSVG.add(ICON.libreAhorroIconAcs, `<svg xmlns="http://www.w3.org/2000/svg" width="74" height="46" viewBox="0 0 74 46">` +
            `<g fill="none" fill-rule="evenodd" transform="translate(1 1.42)">` +
            `<ellipse cx="34.286" cy="23.949" fill="#0040A8" rx="28.052" ry="20.527"/>` +
            `<path fill="#FFF" fill-rule="nonzero" d="M19.37 12.919c0 .855-.724 ` +
            `1.55-1.615 1.55-.889 0-1.614-.695-1.614-1.55 0-.855.725-1.55 1.614-1.55.89 0 1.615.695 1.615 1.55z"/>` +
            `<path fill="#002C76" d="M9.796 31.618c4.482 2.107 9.84 3.332 15.598 3.332 ` +
            `15.751 0 28.52-9.17 28.52-20.483 0-4.068-1.65-7.859-4.498-11.046 7.782 3.658 ` +
            `12.923 9.973 12.923 17.151 0 11.312-12.77 20.483-28.52 20.483-10.088 ` +
            `0-18.952-3.761-24.023-9.437z"/>` +
            `<path fill="#FFF" d="M26.46 4.277c-.438 0-.634.191-.634.427s.196.428.633.428H39.6c.438 ` +
            `0 .476-.192.476-.428s-.038-.427-.475-.427H26.46z"/>` +
            `<path fill-rule="nonzero" stroke="#FFF" stroke-width="1.565" d="M33.127 40.2c14.226 ` +
            `0 25.997-7.29 27.502-17.356.03-.2.037-1.475.032-2.622-.004-.924-.017-1.764-.032-1.894C59.396 ` +
            `8.057 47.554 0 33.127 0 20.764 0 10.3 5.917 6.782 14.074h-4.45c-3.11 0-3.11 12.665 ` +
            `0 12.665h4.45c3.767 7.838 14.294 13.46 26.345 13.46z"/>` +
            `<path fill-rule="nonzero" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" ` +
            `stroke-width="1.565" d="M60.705 20.763c2.924.328 5.188-.584 6.792-2.735 ` +
            `1.663-2.23.225-4.286-1.286-4.227-.676.027-2.672.384-2.241 2.93.436 2.572 8.008 3.934 8.008-1.405"/>` +
            `<path fill="#FDC130" d="M37.365 23.327c0 .974-.277 1.775-.832 2.403-.555.628-1.35 ` +
            `1.037-2.382 1.226v1.434h-1.78v-1.342a11.594 11.594 0 0 1-.928-.092 21.62 21.62 0 0 ` +
            `1-2.382-.505v-1.663c1.327.294 2.443.441 3.349.441 1.511 0 2.267-.52 2.267-1.562a1.32 1.32 0 0 ` +
            `0-.287-.859c-.191-.236-.514-.464-.966-.684l-1.98-1.002c-.791-.41-1.364-.87-1.718-1.378-.354-.508-.53-1.121-.53-1.838 0-.63.12-1.185.363-1.663a2.946 ` +
            `2.946 0 0 1 1.086-1.171c.481-.303 1.057-.5 1.726-.593v-1.405h1.78v1.35c.625.043 ` +
            `1.553.224 2.784.542v1.663c-1.13-.306-2.156-.46-3.08-.46-1.347 0-2.02.448-2.02 1.342 0 .331.106.607.316.827.21.22.616.478 ` +
            `1.215.772l1.665.808c.816.392 1.41.858 1.78 1.397.37.54.554 1.21.554 2.012z"/>` +
            `<path fill-rule="nonzero" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" ` +
            `stroke-width="1.565" d="M53.327 38.489l.785 2.64c.117.637-.144 1.025-.785 1.163-.64.139-2.01.486-4.106 1.042-.844.188-1.369-.05-1.575-.716a23.555 ` +
            `23.555 0 0 0-.447-1.346M15.951 38.489l-.784 2.64c-.118.637.144 1.025.784 1.163.64.139 2.01.486 4.107 1.042.843.188 1.368-.05 ` +
            `1.575-.716.206-.665.355-1.113.446-1.346"/>` +
            `</g>` +
            `</svg>`);
        ImageFactory.dicSVG.add(ICON.flexiAhorroIconAcs, `<svg xmlns="http://www.w3.org/2000/svg" width="73" height="56" viewBox="0 0 73 56">` +
            `<g fill="none" fill-rule="evenodd">` +
            `<path fill="#0040A8" fill-rule="nonzero" d="M9.636 0H67a6 6 0 0 1 6 ` +
            `6v13.953a6 6 0 0 1-6 6H9.636a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6z"/>` +
            `<path fill="#002C76" fill-rule="nonzero" d="M22.988 17.53h27.186a2 ` +
            `2 0 0 1 1.981 1.723l4.396 31.502a4 4 0 0 1-3.962 4.552H22.041a4 4 ` +
            `0 0 1-3.983-4.37l2.939-31.593a2 2 0 0 1 1.991-1.814z"/>` +
            `<path stroke="#FFF" stroke-width="2" d="M20.768 16.088l-4.395 32.83c.003 ` +
            `2.006 1.211 3.515 3.514 3.515h32.427c2.375 0 3.515-1.512 3.515-3.514l-4.393-33.302"/>` +
            `<path stroke="#FFF" d="M25.933 15.617l-1.757 35.978"/>` +
            `<path fill="#FDC130" d="M45.731 37.778h3.507a2 2 0 0 1 1.993 1.842l.562 ` +
            `7.078a2 2 0 0 1-1.994 2.158H45.17a2 2 0 0 1-1.994-2.158l.562-7.078a2 2 0 0 1 1.993-1.842z"/>` +
            `<path stroke="#FFF" stroke-width="2" d="M19.218 28.244H4.707a3.525 3.525 ` +
            `0 0 1-3.515-3.514V7a3.525 3.525 0 0 1 3.515-3.515H65.82A3.525 3.525 0 0 ` +
            `1 69.335 7v17.73a3.525 3.525 0 0 1-3.514 3.515H52.984"/>` +
            `<path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" ` +
            `stroke-width="2" d="M10.173 15.864h50.181"/>` +
            `<path stroke="#FDC130" stroke-linecap="round" stroke-width="2" d="M51.736 9.714h9.497"/>` +
            `</g>` +
            `</svg>`);
        ImageFactory.dicSVG.add(ICON.uncheckedDeliveryWayMobile, `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">` +
            `<rect width="18" height="18" x="270" y="211" fill="#000" fill-opacity=".05" fill-rule="evenodd" stroke="#000" stroke-opacity=".05" stroke-width="2" ` +
            `rx="9" transform="translate(-269 -210)"/></svg>`);
        ImageFactory.dicSVG.add(ICON.checkedDeliveryWayMobile, `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">` +
            `<g fill="none" fill-rule="evenodd">` +
            `<rect width="20" height="20" fill="#7AD468" rx="10"/>` +
            `<path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.25 10l2.5 2.5 5-5"/></g></svg>`);
        ImageFactory.dicSVG.add(ICON.homeDeliveryWayMobile, `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">` +
            `<g fill="none" fill-rule="evenodd" stroke="#CCC" stroke-linejoin="round">` +
            `<path stroke-width="1.08" d="M4.395 10.844H1.598a.183.183 0 0 1-.167-.108.169.169 0 0 1 .038-.189l9.482-9.03c.275-.262.649-.41 ` +
            `1.038-.41.39 0 .763.148 1.038.41l9.496 9.044c.052.05.067.124.039.189a.183.183 0 0 1-.167.108h-2.479"/>` +
            `<path stroke-width="1.152" d="M4.15 7.995V3.102H6.9v2.274M20.826 22.543H3.163M3.163 19.263v-8.419h17.663v8.193"/>` +
            `<path stroke-width="1.152" d="M9.84 22.543v-6.157c0-.545.228-1.067.633-1.452.406-.385.955-.6 1.527-.598 1.189 0 2.152.918 2.152 ` +
            `2.05v6.157M5.158 12.925H7.97v3.101H5.158zM16.022 12.925h2.812v3.101h-2.812zM15.38 22.543c-.098 0 .074-.954.096-1.013.253-.761.876-1.118 ` +
            `1.654-1.303.081-.407.232-.799.447-1.16.458-.736 1.388-1.075 2.245-.817.857.258 1.414 1.044 1.345 1.897a.26.26 0 0 0 .058.206.305.305 0 0 0 ` +
            `.195.045c1.202.028 1.61 1.18 1.536 2.148h-7.575v-.003zM1.08 22.543c-.098 0 .074-.954.096-1.013.253-.761.876-1.118 ` +
            `1.654-1.303.081-.407.232-.799.447-1.16.458-.736 1.388-1.075 2.245-.817.857.258 1.414 1.044 1.345 1.897a.26.26 0 0 0 .058.206.305.305 0 0 0 ` +
            `.195.045c1.202.028 1.61 1.18 1.536 2.148H1.081v-.003z"/><path stroke-linecap="round" stroke-width="1.152" d="M9.84 18.44h1.397"/></g></svg>`);
        ImageFactory.dicSVG.add(ICON.officeDeliveryWayMobile, `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">` +
            `<path fill="#CCC" fill-rule="nonzero" stroke="#CCC" stroke-width=".5" ` +
            `d="M22.693 18.265h-1.891V6.327c0-.203-.138-.368-.307-.368h-3.583v.735h3.276v11.571h-3.276V1.367c0-.203-.137-.367-.307-.367h-9.21c-.17 ` +
            `0-.307.164-.307.367v16.898H3.812V6.694h3.276v-.735H3.505c-.17 0-.307.165-.307.368v11.938H1.307c-.17 0-.307.165-.307.368 0 ` +
            `.203.137.367.307.367h21.386c.17 0 .307-.164.307-.367 0-.203-.137-.368-.307-.368zm-6.395 0H7.702V1.735h8.596v16.53zM9.237 ` +
            `5.338V3.641c0-.203.137-.367.307-.367.17 0 .307.164.307.367v1.701c0 .203-.139.367-.309.366-.17-.001-.306-.167-.305-.37zm2.456-1.697c0-.203.137-.367.307-.367.17 ` +
            `0 .307.164.307.367v1.701c0 .203-.139.367-.309.366-.17-.001-.306-.167-.305-.37V3.641zm2.456 1.697V3.641c0-.203.137-.367.307-.367.17 0 .307.164.307.367v1.701c0 ` +
            `.203-.139.367-.308.366-.17-.001-.307-.167-.306-.37zM9.237 9.012V7.315c0-.203.137-.368.307-.368.17 0 .307.165.307.368v1.7c0 ` +
            `.203-.139.367-.309.366-.17-.001-.306-.166-.305-.37zm2.456-1.697c0-.203.137-.368.307-.368.17 0 .307.165.307.368v1.7c0 ` +
            `.203-.139.367-.309.366-.17-.001-.306-.166-.305-.37V7.316zm2.456 1.697V7.315c0-.203.137-.368.307-.368.17 0 .307.165.307.368v1.7c0 ` +
            `.203-.139.367-.308.366-.17-.001-.307-.166-.306-.37zm-4.912 3.673v-1.697c0-.203.137-.367.307-.367.17 0 .307.164.307.367v1.701c0 .203-.139.367-.309.365-.17 ` +
            `0-.306-.166-.305-.369zm2.456-1.697c0-.203.137-.367.307-.367.17 0 .307.164.307.367v1.701c0 .203-.139.367-.309.365-.17 0-.306-.166-.305-.369v-1.697zm2.456 ` +
            `1.697v-1.697c0-.203.137-.367.307-.367.17 0 .307.164.307.367v1.701c0 .203-.139.367-.308.365-.17 0-.307-.166-.306-.369zM9.237 ` +
            `16.36v-1.697c0-.203.137-.368.307-.368.17 ` +
            `0 .307.165.307.368v1.7c0 .203-.139.367-.309.366-.17-.001-.306-.166-.305-.37zm2.456-1.697c0-.203.137-.368.307-.368.17 0 .307.165.307.368v1.7c0 ` +
            `.203-.139.367-.309.366-.17-.001-.306-.166-.305-.37v-1.696zm2.456 1.697v-1.697c0-.203.137-.368.307-.368.17 0 .307.165.307.368v1.7c0 ` +
            `.203-.139.367-.308.366-.17-.001-.307-.166-.306-.37z"/></svg>`);
        ImageFactory.dicSVG.add(ICON.uncheckedDeliveryWay, `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">` +
            `<path fill="#000" fill-opacity=".05" fill-rule="evenodd" stroke="#000" stroke-opacity=".05" stroke-width="2" ` +
            `d="M15 1C7.268 1 1 7.268 1 15s6.268 14 14 14 14-6.268 ` +
            `14-14S22.732 1 15 1z"/></svg>`);
        ImageFactory.dicSVG.add(ICON.checkedDeliveryWay, `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><g fill="none" fill-rule="evenodd">` +
            `<rect width="30" height="30" fill="#7AD468" rx="15"/><path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" ` +
            `stroke-width="2" d="M9 15l3.667 4L20 11"/></g></svg>`);
        ImageFactory.dicSVG.add(ICON.homeDeliveryWay, `<svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35">` +
            `<g fill="none" fill-rule="evenodd" stroke="#CCC" stroke-linejoin="round">` +
            `<path stroke-width="1.5" d="M5.939 16h-4.07a.267.267 0 0 1-.186-.452l13.792-13.76a2.136 2.136 0 0 1 3.018 0L32.307 15.57a.266.266 0 ` +
            `0 1-.187.452h-3.605"/>` +
            `<path stroke-width="1.6" d="M5.581 11.658V4.203h4v3.465M29.837 33.827H4.147M4.147 28.83V16h25.69v12.484"/>` +
            `<path stroke-width="1.6" d="M13.859 33.827v-9.382c0-.83.331-1.626.92-2.212A3.134 3.134 0 0 1 17 21.322a3.127 3.127 0 0 1 3.13 ` +
            `3.123v9.382M7.048 19.172h4.091v4.725H7.048zM22.851 19.172h4.091v4.725h-4.091zM21.917 33.827c-.144 0 .107-1.453.139-1.543.368-1.16 ` +
            `1.275-1.703 2.405-1.985a5.75 5.75 0 0 1 .651-1.767 2.814 2.814 0 0 1 3.265-1.246 2.804 2.804 0 0 1 1.956 2.89.41.41 0 0 0 ` +
            `.086.314c.083.056.183.08.282.07 1.75.042 2.342 1.798 2.235 3.272H21.917v-.005zM1.117 33.827c-.144 0 .107-1.453.139-1.543.368-1.16 ` +
            `1.275-1.703 2.405-1.985a5.75 5.75 0 0 1 .651-1.767 2.814 2.814 0 0 1 3.265-1.246 2.804 2.804 0 0 1 1.956 2.89.41.41 0 0 0 ` +
            `.086.314c.083.056.183.08.282.07 1.75.042 2.342 1.798 2.235 3.272H1.117v-.005z"/>` +
            `<path stroke-linecap="round" stroke-width="1.6" d="M13.859 27.574h2.032"/></g></svg>`);
        ImageFactory.dicSVG.add(ICON.officeDeliveryWay, `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="32" viewBox="0 0 38 32">` +
            `<path fill="#CCC" fill-rule="nonzero" stroke="#CCC" stroke-width=".5" ` +
            `d="M36.498 29.776h-3.095V9.878c0-.339-.225-.613-.502-.613h-5.863v1.225h5.36v19.286h-5.36V1.612c0-.338-.225-.612-.502-.612H11.464c-.277 ` +
            `0-.502.274-.502.612v28.164h-5.36V10.49h5.36V9.265H5.099c-.277 0-.502.274-.502.613v19.898H1.502c-.277 0-.502.274-.502.612 0 .338.225.612.502.612h34.996c.277 0 ` +
            `.502-.274.502-.612 0-.338-.225-.612-.502-.612zm-10.465 0H11.967V2.224h14.066v27.552zM14.48 ` +
            `8.23V5.402c0-.338.225-.612.502-.612s.502.274.502.612v2.835c-.001.338-.227.61-.504.609-.278-.002-.502-.277-.5-.615zm4.019-2.829c0-.338.225-.612.502-.612s.502.274.` +
            `502.612v2.835c-.001.338-.227.61-.505.609-.277-.002-.5-.277-.5-.615V5.402zm4.019 2.829V5.402c0-.338.225-.` +
            `612.502-.612s.502.274.502.612v2.835c-.001.338-.227.61-.505.` +
            `609-.277-.002-.5-.277-.5-.615zm-8.038 6.122v-2.829c0-.338.225-.612.502-.612s.502.274.502.612v2.835c-.` +
            `001.338-.227.611-.504.61-.278-.002-.502-.278-.5-.616zm4.019-2.` +
            `829c0-.338.225-.612.502-.612s.502.274.502.612v2.835c-.001.338-.227.611-.505.61-.277-.002-.5-.278-.5-.` +
            `616v-2.829zm4.019 2.83v-2.83c0-.338.225-.612.502-.612s.502.274.` +
            `502.612v2.835c-.001.338-.227.611-.505.61-.277-.002-.5-.278-.5-.616zm-8.038 6.122v-2.83c0-.337.225-.611.502-.611s.502.274.` +
            `502.612v2.835c-.001.338-.227.61-.504.609-.278-.` +
            `002-.502-.277-.5-.615zm4.019-2.83c0-.337.225-.611.502-.611s.502.274.502.612v2.835c-.001.338-.227.61-.505.609-.277-.002-.5-.` +
            `277-.5-.615v-2.83zm4.019 2.83v-2.83c0-.337.225-.` +
            `611.502-.611s.502.274.502.612v2.835c-.001.338-.227.61-.505.609-.277-.002-.5-.277-.5-.615zm-8.038 6.122v-2.829c0-.` +
            `338.225-.612.502-.612s.502.274.502.612v2.835c-.001.338-.227.` +
            `611-.504.61-.278-.002-.502-.278-.5-.616zm4.019-2.829c0-.338.225-.612.502-.612s.502.274.502.612v2.835c-.001.338-.227.611-.` +
            `505.61-.277-.002-.5-.278-.5-.616v-2.829zm4.019 2.829v-2.` +
            `829c0-.338.225-.612.502-.612s.502.274.502.612v2.835c-.001.338-.227.611-.505.61-.277-.002-.5-.278-.5-.616z"/></svg>`);
        ImageFactory.dicSVG.add(ICON.scrollDownButton, `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">` +
            `<g fill="none" fill-rule="evenodd">` +
            `<circle cx="24" cy="24" r="24" fill="#0040A8"/>` +
            `<path fill="#EFEFEF" d="M23.99 15.429c-.596 0-1.084.488-1.084 1.085v12.613l-4.85-4.15a1.097 1.097 0 0 0-1.517.108 1.1 1.1 0 0 0 .109` +
            ` 1.519l6.638 5.696c.19.163.46.271.704.271.244` +
            ` 0 .515-.08.704-.27l6.638-5.697c.46-.38.515-1.085.109-1.519a1.05 1.05 0 0 0-1.518-.109l-4.85 4.15V16.515c0-.597-.487-1.085-1.083-1.085"/>` +
            `</g></svg>`);
        ImageFactory.dicSVG.add(ICON.alert, `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 48"><g id="warning">` +
            `<rect width="48" height="48" rx="24"/><rect width="4" height="17" x="22" y="12" fill="#FFF" rx="2"/><rect width="4" height="4" x="22" ` +
            `y="31" fill="#FFF" rx="2"/></g></svg>`);
        ImageFactory.dicSVG.add(ICON.success, `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 30 30"><g><rect ` +
            `width="30" height="30" rx="15"/><path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.375 15l3.75 ` +
            `3.75 7.5-7.5"/></g></svg>`);
        ImageFactory.dicSVG.add(ICON.back, `<svg xmlns="http://www.w3.org/2000/svg" width = "100%" height = "100%" viewBox = "0 0 36 36" ><g ` +
            `fill="none" fill - rule="evenodd"><path stroke="#002C76" stroke - linecap="round" stroke - linejoin="round" stroke - width="2" ` +
            `d = "M21 28.5L10.5 17.942 21 7.5"/></g></svg>`);
        ImageFactory.dicSVG.add(ICON.close, `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36"><g ` +
            `fill="none" fill-rule="evenodd"><path stroke="#002C76" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" ` +
            `d="M18 18l-9 9 9-9 9 9-9-9zm0 0L9 9l9 9 9-9-9 9z"/></g></svg>`);
        ImageFactory.dicSVG.add(ICON.logoBdBsmall, `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 44 44"><defs>` +
            `<linearGradient id="a" x1="50%" y1="57.371%" y2="57.371%">` +
            `<stop offset="0%" stop-color="#ED1C24"/><stop offset="24.451%" stop-color="#EE2830" stop-opacity=".945"/><stop offset="100%" stop-color="#FFF" stop-opacity="0"/>` +
            `</linearGradient><linearGradient id="b" x1="10.322%" x2="88.651%" y1="49.953%" y2="49.953%">` +
            `<stop offset="0%" stop-color="#ED1C24"/><stop offset="100%" stop-color="#FFCB05"/>` +
            `</linearGradient><linearGradient id="c" x1="50.002%" x2="50.002%" y1="-.786%" y2="35.658%">` +
            `<stop offset="0%" stop-color="#E39717"/><stop offset="12.36%" stop-color="#E8A116"/>` +
            `<stop offset="43.44%" stop-color="#F4B710"/><stop offset="73.21%" stop-color="#FCC509"/><stop offset="100%" stop-color="#FFCB05"/>` +
            `</linearGradient><linearGradient id="d" x1="27.043%" x2="46.808%" y1="24.367%" y2="60.204%">` +
            `<stop offset="0%" stop-color="#E39717"/><stop offset="10.13%" stop-color="#E8A116"/>` +
            `<stop offset="35.61%" stop-color="#F4B710"/><stop offset="60%" stop-color="#FCC509"/>` +
            `<stop offset="81.96%" stop-color="#FFCB05"/><stop offset="100%" stop-color="#FFCB05"/>` +
            `</linearGradient></defs><g fill="none" fill-rule="evenodd"><g fill-rule="nonzero">` +
            `<path fill="url(#a)" d="M21.94.006C10.174.214.445 8.528.016 19.951-.41 31.381 8.403 41 19.696 41.428c5.746.22 ` +
            `11.03-1.98 14.897-5.705 3.72-3.59 3.566-9.905.61-7.042-3.252 3.148-7.799` +
            ` 5.03-12.756 4.848-9.453-.344-16.827-8.067-16.475-17.251C6.3 7.756 14.65 1.012 22.12.604L22.313 0c-.122.006-.244.006-.372.006z"/>` +
            `<path fill="#FFCB05" d="M23.59.604c9.485.584 17.293 8.771-10.312 16.959 7.033 1.402 12.227 4.746 12.227 4.746C49.138 10.129 35.695-.04 ` +
            `22.322 0l-.193.604c.494-.033.98-.033 1.46 0z"/><path fill="url(#b)" d="M12.023 4.91C15.003 2.39 18.7.796 22.214.6c.495-.025.983-.025 ` +
            `1.464.007 4.662.287 8.92 2.409 8.792 5.477l2.33-2.787C31.687 1.2 27.044-.013 22.408 0h-.373c-4.367.078-8.45` +
            ` 1.273-11.88 3.336l1.869 1.573z"/><path fill="url(#c)" d="M33.76 32.36c-.006-.11-.39-2.814-.39-2.814-.957-6.427.378-12.653 ` +
            `10.11-13.166-14.116-6.855-23.5-.292-30.375 1.246 6.258.784 19.45 7.931 20.656 14.735z"/>` +
            `<path fill="url(#d)" d="M38.413 18.253c-6.016 2.508-5.4 9.456-4.822 13.531.116.332.72 2.867-.674 5.512-1.727 2.977-4.526 5.225-9.919 6.681 13.822.65 ` +
            `29.641-12.79 15.415-25.724z"/></g></g></svg>`);
        ImageFactory.dicSVG.add(ICON.logoBdBLarge, `<?xml version="1.0" encoding="UTF-8"?>` +
            `<svg width="100%" height="100%" viewBox="0 0 199 39" version="1.1" xmlns="http://www.w3.org/2000/svg" ` +
            `xmlns:xlink="http://www.w3.org/1999/xlink"> ` +
            `<!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch --> ` +
            `<title>logo/horizontal-pos-medium</title><desc>Created with Sketch.</desc><defs> ` +
            `<linearGradient x1="28.5955376%" y1="44.4035565%" x2="87.1535448%" y2="57.3710305%" id="linearGradient-1"> ` +
            `<stop stop-color="#ED1C24" offset="0%"></stop><stop stop-color="#FFFFFF" offset="100%"></stop></linearGradient> ` +
            `<linearGradient x1="10.3215998%" y1="49.9533251%" x2="88.6510422%" y2="49.9533251%" id="linearGradient-2"> ` +
            `<stop stop-color="#ED1C24" offset="0%"></stop><stop stop-color="#FFCB05" offset="100%"></stop></linearGradient> ` +
            `<linearGradient x1="50.0021978%" y1="-0.786073229%" x2="50.0021978%" y2="35.6584867%" id="linearGradient-3"> ` +
            `<stop stop-color="#E39717" offset="0%"></stop><stop stop-color="#E8A116" offset="12.36%"></stop> ` +
            `<stop stop-color="#F4B710" offset="43.44%"></stop><stop stop-color="#FCC509" offset="73.21%"></stop> ` +
            `<stop stop-color="#FFCB05" offset="100%"></stop></linearGradient> ` +
            `<linearGradient x1="27.0427298%" y1="24.3674127%" x2="46.8081566%" y2="60.2040022%" id="linearGradient-4"> ` +
            `<stop stop-color="#E39717" offset="0%"></stop><stop stop-color="#E8A116" offset="10.13%"></stop> ` +
            `<stop stop-color="#F4B710" offset="35.61%"></stop><stop stop-color="#FCC509" offset="60%"></stop> ` +
            `<stop stop-color="#FFCB05" offset="81.96%"></stop><stop stop-color="#FFCB05" offset="100%"></stop></linearGradient></defs> ` +
            `<g id="Todos-Landing-v-1.0" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> ` +
            `<g id="Landing---Desktop" transform="translate(-128.000000, -45.000000)"> ` +
            `<g id="logo/horizontal-pos-medium" transform="translate(128.000000, 45.000000)"><g id="logo-full"> ` +
            `<g id="logo" transform="translate(159.313688, 0.110070)" fill-rule="nonzero"> ` +
            `<path d="M19.7595948,0.00565019504 C9.1615614,0.186456436 0.40013545,7.42435628 0.0131772834,17.3686995 ` +
            `C-0.368005388,27.318693 7.56752478,35.692282 17.7381715,36.0651949 C22.9130151,36.2573015 ` +
            `27.672023,34.3418854 31.1546465,31.0986735 C34.5044336,27.9741156 34.3658217,22.4764759 31.7033185,24.9682119 ` +
            `C28.7751425,27.7085565 24.6803165,29.347113 20.2158589,29.1889076 C11.7027792,28.8894472 5.06095994,22.1657151 ` +
            `5.37861216,14.1706891 C5.67316241,6.75198307 13.1928569,0.881430425 19.9213086,0.525468138 L20.0945735, ` +
            `0 C19.9848391,0.00565019504 19.8751047,0.00565019504 19.7595948,0.00565019504 Z" id="icon" ` +
            `fill="url(#linearGradient-1)"></path> ` +
            `<path d="M21.2448782,0.525748243 C29.7874532,1.03444287 36.8187827,8.16181981 11.9582166,15.2891968 ` +
            `C18.2916045,16.5100639 22.9695439,19.4209276 22.9695439,19.4209276 C44.25388,8.81747066 32.1466113, ` +
            `-0.0338158464 20.1027919,9.71287613e-05 L19.9297485,0.525748243 C20.3738932,0.497487431 20.8122697,0.497487431 ` +
            `21.2448782,0.525748243 Z" id="icon" fill="#FFCB05"></path> ` +
            `<path d="M10.8275079,4.27368242 C13.5110371,2.0800569 16.8423148,0.693412788 20.005872,0.522923757 ` +
            `C20.451199,0.500191887 20.8907426,0.500191887 21.3245027,0.528606725 C25.5233007,0.778657303 29.3577401, ` +
            `2.6256218 29.2420708,5.29661661 L31.3414698,2.86998941 C28.5364877,1.04575678 24.3550401,-0.0112752043 ` +
            `20.179376,9.07310347e-05 L19.8439349,9.07310347e-05 C15.9111765,0.0682863432 12.2328907,1.10826943 9.14451861, ` +
            `2.90408721 L10.8275079,4.27368242 Z" id="icon" fill="url(#linearGradient-2)"></path> ` +
            `<path d="M30.4049578,28.1718593 C30.3991769,28.0758765 30.0523206,25.7214744 30.0523206,25.7214744 ` +
            `C29.1909609,20.1262406 30.393396,14.7060343 39.1572976,14.2599965 C26.4450155,8.29212345 17.9932844, ` +
            `14.0059243 11.8019001,15.3440377 C17.4383144,16.0272096 29.3181415,22.2491547 30.4049578,28.1718593 Z" ` +
            `id="icon" fill="url(#linearGradient-3)"></path> ` +
            `<path d="M34.5942324,15.8898498 C29.1766129,18.0738935 29.7316732,24.1224496 30.2520422,27.6701061 ` +
            `C30.356116,27.9586714 30.8996125,30.1653477 29.644945,32.4682124 C28.0896199,35.0596424 25.5687212, ` +
            `37.0173603 20.7119439,38.2847846 C33.1603267,38.850599 47.4068736,27.1495568 34.5942324,15.8898498 Z" ` +
            `id="icon" fill="url(#linearGradient-4)"></path> ` +
            `</g> ` +
            `<g id="banco-de-bogota" transform="translate(0.000000, 10.930706)" fill="#002C76" fill-rule="nonzero"> ` +
            `<path d="M45.2236444,13.0167186 C44.1035541,13.29367 43.2634864,13.4321458 42.4234187,13.4321458 ` +
            `C40.3232495,13.4321458 39.9032157,11.6319613 39.9032157,10.1087283 C39.9032157,7.75464086 40.7432833, ` +
            `6.50835929 42.4234187,6.50835929 C43.2634864,6.50835929 43.8235316,6.64683502 43.8235316,6.64683502 L45.3636557, ` +
            `7.20073794 L45.3636557,5.26207772 L45.2236444,5.12360199 C44.6635993,4.84665054 43.4034977,4.43122335 41.8633736, ` +
            `4.43122335 C38.7831254,4.43122335 36.8229674,6.64683502 36.8229674,9.97025253 C36.8229674,13.1551943 38.7831254, ` +
            `15.2323303 41.583351,15.2323303 C42.8434526,15.2323303 43.9635429,14.9553788 45.6436783,14.2630001 L45.9237008, ` +
            `14.1245244 L45.503667,12.6012914 L45.2236444,13.0167186 Z" id="Shape"></path> ` +
            `<path d="M9.80078981,7.61616513 C11.2009026,7.06226221 11.7609478,6.0929321 11.7609478,4.43122335 ` +
            `C11.7609478,2.90799032 11.0608914,1.66170875 9.80078981,1.24628157 C8.96072211,0.830854377 8.12065441, ` +
            `0.830854377 5.46044004,0.830854377 L0,0.830854377 L0,2.3540874 C0,2.3540874 1.26010155,2.49256313 1.6801354, ` +
            `2.63103886 L1.6801354,15.370806 L6.02048517,15.370806 C8.12065441,15.370806 9.10073339,15.2323303 9.94080109, ` +
            `14.8169031 C11.3409139,14.1245244 12.1809816,12.8782429 12.1809816,11.2165341 C12.3209929,9.27787388 11.4809252, ` +
            `8.03159232 9.80078981,7.61616513 Z M4.76038362,2.63103886 L5.32042875,2.63103886 L5.60045132,2.63103886 ` +
            `C6.72054158,2.63103886 7.56060928,2.63103886 8.12065441,3.04646605 C8.54068826,3.32341751 8.68069955, ` +
            `3.87732043 8.68069955,4.43122335 C8.68069955,5.81598064 8.2606657,6.50835929 6.5805303,6.64683502 L5.32042875, ` +
            `6.78531075 L4.76038362,7.75464086 L4.76038362,2.63103886 Z M8.2606657,12.8782429 C7.56060928,13.4321458 ` +
            `6.5805303,13.4321458 5.32042875,13.4321458 L4.76038362,13.4321458 L4.76038362,8.72397096 L4.9003949,8.72397096 ` +
            `L5.88047389,8.72397096 C7.00056415,8.72397096 7.84063185,8.72397096 8.40067698,9.13939815 C8.82071083,9.55482534 ` +
            `9.10073339,10.1087283 9.10073339,10.9395826 C9.10073339,11.770437 8.82071083,12.4628157 8.2606657,12.8782429 Z" ` +
            `id="Shape"></path> ` +
            `<path d="M95.6277063,7.61616513 C97.0278191,7.06226221 97.5878642,6.0929321 97.5878642,4.43122335 ` +
            `C97.5878642,2.90799032 96.8878078,1.66170875 95.6277063,1.24628157 C94.7876386,0.830854377 93.9475709, ` +
            `0.830854377 91.2873565,0.830854377 L85.8269165,0.830854377 L85.8269165,2.3540874 C85.8269165,2.3540874 ` +
            `87.087018,2.49256313 87.5070519,2.63103886 L87.5070519,15.370806 L91.8474016,15.370806 C93.9475709,15.370806 ` +
            `94.9276499,15.2323303 95.7677176,14.8169031 C97.1678304,14.1245244 98.0078981,12.8782429 98.0078981,11.2165341 ` +
            `C98.1479094,9.27787388 97.3078417,8.03159232 95.6277063,7.61616513 Z M90.5873001,2.63103886 L91.1473452, ` +
            `2.63103886 L91.4273678,2.63103886 C92.5474581,2.63103886 93.3875258,2.63103886 93.9475709,3.04646605 ` +
            `C94.3676047,3.32341751 94.507616,3.87732043 94.507616,4.43122335 C94.507616,5.81598064 94.0875822,6.50835929 ` +
            `92.4074468,6.64683502 L91.1473452,6.78531075 L90.5873001,7.75464086 L90.5873001,2.63103886 Z M94.0875822, ` +
            `12.8782429 C93.3875258,13.4321458 92.4074468,13.4321458 91.1473452,13.4321458 L90.5873001,13.4321458 ` +
            `L90.5873001,8.72397096 L90.7273114,8.72397096 L91.7073904,8.72397096 C92.8274806,8.72397096 93.6675483, ` +
            `8.72397096 94.2275935,9.13939815 C94.6476273,9.55482534 94.9276499,10.1087283 94.9276499,10.9395826 ` +
            `C95.0676611,11.770437 94.6476273,12.4628157 94.0875822,12.8782429 Z" id="Shape"></path> ` +
            `<path d="M32.4826177,4.56969908 C31.64255,4.56969908 30.5224597,4.84665054 29.1223469,5.40055345 ` +
            `L29.1223469,4.56969908 L24.7819971,5.12360199 L24.7819971,6.64683502 L26.4621325,6.64683502 L26.4621325, ` +
            `15.2323303 L29.4023694,15.2323303 L29.4023694,7.20073794 C29.8224033,7.06226221 30.662471,6.78531075 31.64255, ` +
            `6.78531075 C32.0625838,6.78531075 32.4826177,6.92378648 32.6226289,7.06226221 C32.9026515,7.33921367 32.9026515, ` +
            `7.89311659 32.9026515,8.58549523 L32.9026515,15.2323303 L35.8428884,15.2323303 L35.8428884,8.4470195 C35.8428884, ` +
            `6.92378648 35.7028772,6.36988356 35.2828433,5.67750491 C34.5827869,4.98512626 33.7427192,4.56969908 32.4826177, ` +
            `4.56969908 Z" id="Shape"></path> ` +
            `<path d="M51.6641634,4.56969908 C48.4439039,4.56969908 46.3437347,6.64683502 46.3437347,9.97025253 ` +
            `C46.3437347,13.29367 48.3038926,15.370806 51.6641634,15.370806 C54.8844229,15.370806 56.8445809,13.29367 ` +
            `56.8445809,9.97025253 C56.8445809,6.64683502 54.8844229,4.56969908 51.6641634,4.56969908 Z M51.6641634, ` +
            `13.5706215 C50.6840844,13.5706215 49.4239829,13.1551943 49.4239829,9.97025253 C49.4239829,6.78531075 ` +
            `50.5440732,6.36988356 51.6641634,6.36988356 C52.6442424,6.36988356 53.904344,6.78531075 53.904344,9.8317768 ` +
            `C53.7643327,13.1551943 52.6442424,13.5706215 51.6641634,13.5706215 Z" id="Shape"></path> ` +
            `<path d="M127.69029,4.56969908 C124.470031,4.56969908 122.369861,6.64683502 122.369861,9.97025253 ` +
            `C122.369861,13.29367 124.330019,15.370806 127.69029,15.370806 C130.91055,15.370806 133.010719,13.29367 ` +
            `133.010719,9.97025253 C133.010719,6.64683502 131.050561,4.56969908 127.69029,4.56969908 Z M127.69029, ` +
            `13.5706215 C126.710211,13.5706215 125.45011,13.1551943 125.45011,9.97025253 C125.45011,6.78531075 ` +
            `126.5702,6.36988356 127.69029,6.36988356 C128.670369,6.36988356 129.930471,6.78531075 129.930471,9.8317768 ` +
            `C129.930471,13.1551943 128.81038,13.5706215 127.69029,13.5706215 Z" id="Shape"></path> ` +
            `<path d="M140.011283,13.29367 L137.911114,13.5706215 C137.49108,13.5706215 137.351069,13.4321458 ` +
            `137.211057,13.29367 C137.211057,13.1551943 137.071046,13.1551943 137.071046,12.3243399 L137.071046,6.64683502 ` +
            `L140.011283,6.64683502 L140.011283,4.84665054 L137.071046,4.84665054 L137.071046,2.07713594 L136.651012, ` +
            `2.07713594 L134.130809,2.3540874 L134.130809,12.1858642 C134.130809,13.29367 134.27082,13.9860487 134.690854, ` +
            `14.5399516 C135.110888,15.0938545 135.810944,15.370806 136.791023,15.370806 C137.771102,15.370806 138.751181, ` +
            `15.0938545 139.871272,14.6784273 L140.151294,14.5399516 L140.011283,13.29367 Z" id="Shape"></path> ` +
            `<path d="M104.868451,4.56969908 C101.648191,4.56969908 99.5480222,6.64683502 99.5480222,9.97025253 ` +
            `C99.5480222,13.29367 101.50818,15.370806 104.868451,15.370806 C108.08871,15.370806 110.048868,13.29367 ` +
            `110.048868,9.97025253 C110.048868,6.64683502 108.08871,4.56969908 104.868451,4.56969908 Z M104.868451, ` +
            `13.5706215 C103.888372,13.5706215 102.62827,13.1551943 102.62827,9.97025253 C102.62827,6.78531075 103.748361, ` +
            `6.36988356 104.868451,6.36988356 C105.84853,6.36988356 107.108631,6.78531075 107.108631,9.8317768 C107.108631, ` +
            `13.1551943 105.988541,13.5706215 104.868451,13.5706215 Z" id="Shape"></path> ` +
            `<path d="M118.309534,5.40055345 C117.609478,4.98512626 116.76941,4.84665054 116.069354,4.84665054 ` +
            `C113.129117,4.84665054 111.028947,7.20073794 111.028947,10.3856797 C111.028947,13.4321458 112.709083,15.370806 ` +
            `115.229286,15.370806 C115.929342,15.370806 116.629399,15.2323303 118.169523,14.5399516 L118.169523,15.370806 ` +
            `C118.169523,17.1709905 117.469466,17.7248934 115.229286,17.7248934 C114.249207,17.7248934 112.849094,17.5864177 ` +
            `111.729004,17.3094662 L111.729004,19.1096507 C113.969184,19.5250779 114.809252,19.6635536 115.929342,19.6635536 ` +
            `C117.329455,19.6635536 118.589557,19.3866021 119.429624,18.971175 C120.689726,18.2787963 121.10976,17.3094662 ` +
            `121.10976,15.5092817 L121.10976,4.98512626 L118.169523,4.98512626 L118.169523,5.40055345 L118.309534,5.40055345 ` +
            `Z M118.309534,13.1551943 C117.749489,13.29367 117.469466,13.29367 116.909421,13.29367 C115.089275,13.29367 ` +
            `114.249207,12.1858642 114.249207,10.1087283 C114.249207,7.89311659 115.369297,6.78531075 117.189444,6.78531075 ` +
            `C117.469466,6.78531075 117.749489,6.78531075 118.309534,6.92378648 L118.309534,13.1551943 Z" id="Shape"></path> ` +
            `<path d="M23.241873,5.12360199 L20.4416473,5.12360199 L20.4416473,5.53902918 C19.7415909,5.12360199 ` +
            `19.0415345,4.98512626 18.2014668,4.98512626 C15.4012411,4.98512626 13.3010719,7.20073794 13.3010719,10.5241554 ` +
            `C13.3010719,13.5706215 14.841196,15.5092817 17.5014104,15.5092817 C18.2014668,15.5092817 19.0415345,15.2323303 ` +
            `20.301636,14.4014759 L20.301636,15.370806 L20.301636,15.370806 L24.3619632,14.9553788 L24.3619632,13.29367 ` +
            `L23.241873,13.29367 L23.241873,5.12360199 L23.241873,5.12360199 Z M20.4416473,13.29367 C19.8816022,13.4321458 ` +
            `19.6015796,13.4321458 19.0415345,13.4321458 C17.2213878,13.4321458 16.2413088,12.3243399 16.2413088,10.247204 ` +
            `C16.2413088,8.03159232 17.3613991,6.78531075 19.3215571,6.78531075 C19.7415909,6.78531075 19.8816022, ` +
            `6.78531075 20.4416473,6.92378648 L20.4416473,13.29367 Z" id="Shape"></path> ` +
            `<path d="M150.792152,5.12360199 L147.991926,5.12360199 L147.991926,5.53902918 C147.29187,5.12360199 ` +
            `146.591813,4.98512626 145.751746,4.98512626 C142.95152,4.98512626 140.851351,7.20073794 140.851351,10.5241554 ` +
            `C140.851351,13.5706215 142.391475,15.5092817 145.051689,15.5092817 C145.751746,15.5092817 146.591813,15.2323303 ` +
            `147.851915,14.4014759 L147.851915,15.370806 L151.912242,14.9553788 L151.912242,13.29367 L150.792152,13.29367 ` +
            `L150.792152,5.12360199 Z M147.851915,13.29367 C147.29187,13.4321458 147.011847,13.4321458 146.451802,13.4321458 ` +
            `C144.631655,13.4321458 143.651576,12.3243399 143.651576,10.247204 C143.651576,8.03159232 144.771667,6.78531075 ` +
            `146.731825,6.78531075 C147.151858,6.78531075 147.29187,6.78531075 147.851915,6.92378648 L147.851915,13.29367 Z" ` +
            `id="Shape"></path> ` +
            `<polygon id="Shape" points="149.392039 2.49256313 148.691983 0.276951459 148.41196 0.415427189 143.791588 2.21561167 ` +
            `144.211621 3.46189324"></polygon> ` +
            `<path d="M70.8457092,0 L70.7056979,0 L70.4256753,0 L66.6453707,0.415427189 L66.6453707,1.66170875 ` +
            `L68.0454835,1.66170875 L68.0454835,4.98512626 C66.9253933,4.56969908 65.2452579,4.29274762 63.845145,4.70817481 ` +
            `C61.8849871,5.40055345 60.6248855,7.20073794 60.6248855,9.8317768 C60.6248855,12.1858642 61.7449758,14.1245244 ` +
            `63.7051338,14.8169031 C65.1052466,15.2323303 67.0654046,14.8169031 68.1854948,14.2630001 L68.1854948,15.0938545 ` +
            `L72.1058107,14.5399516 L72.1058107,13.29367 L70.8457092,13.29367 C70.7056979,13.29367 70.8457092,0 70.8457092, ` +
            `0 Z M67.9054722,13.1551943 C66.6453707,13.5706215 65.1052466,13.7090972 64.2651789,12.6012914 C63.7051338,11.9089127 ` +
            `63.5651225,10.9395826 63.5651225,9.97025253 L63.5651225,9.69330107 C63.5651225,8.58549523 63.845145,7.89311659 ` +
            `64.4051902,7.20073794 C65.2452579,6.23140783 66.785382,6.36988356 67.9054722,6.92378648 C68.0454835,6.92378648 ` +
            `67.9054722,12.8782429 67.9054722,13.1551943 Z" id="Shape"></path> ` +
            `<path d="M77.8462733,4.56969908 C77.1462169,4.56969908 76.5861718,4.56969908 76.0261267,4.84665054 ` +
            `C73.9259574,5.53902918 72.6658559,7.33921367 72.6658559,10.1087283 C72.6658559,12.6012914 73.9259574,14.5399516 ` +
            `76.0261267,15.2323303 C77.8462733,15.7862332 80.5064877,15.2323303 82.3266344,14.2630001 L82.4666457,14.2630001 ` +
            `L82.0466118,12.7397671 L81.0665329,13.0167186 C79.66642,13.4321458 77.8462733,14.1245244 76.7261831,12.7397671 ` +
            `C76.1661379,12.0473885 76.0261267,10.9395826 76.0261267,10.1087283 L81.626578,10.247204 L82.4666457,10.247204 ` +
            `L82.4666457,10.1087283 L82.4666457,9.41634961 C82.3266344,4.43122335 77.9862846,4.56969908 77.8462733,4.56969908 ` +
            `Z M75.7461041,8.72397096 C75.7461041,8.58549523 75.8861154,6.50835929 77.7062621,6.50835929 C79.66642,6.50835929 ` +
            `79.66642,8.58549523 79.66642,8.72397096 L75.7461041,8.72397096 Z" id="Shape"></path></g></g></g></g></g></svg> `);
        // ImageFactory.dicSVG.add(ICON.flexiAhorroIcon, '');
    }

}
