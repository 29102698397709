import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import * as Constants from '../enums/device-detector.constants';

import { DeviceInfoModel } from '../models/device-info.model';

@Injectable()
export class DeviceDetectorService {

  ua = '';
  userAgent = '';
  os = '';
  browser = '';
  device = '';
  osVersion = '';
  browserVersion = '';
  publicIp = '0.0.0.0';
  privateIp = '0.0.0.0';

  constructor(@Inject(PLATFORM_ID) private platformId, private http: HttpClient) {
    if (isPlatformBrowser(this.platformId)) {
      this.ua = window.navigator.userAgent;
    }
    this._setDevice();
  }

  public getDeviceInfo(): DeviceInfoModel {
    const deviceInfo: DeviceInfoModel = {
      userAgent: this.userAgent,
      os: this.os,
      browser: this.browser,
      device: this.device,
      os_version: this.osVersion,
      browser_version: this.browserVersion,
      public_ip: this.publicIp,
      private_ip: this.privateIp
    };

    return deviceInfo;
  }

  private _setDevice() {
    const ua = this.ua;
    this.userAgent = ua;
    const mappings = [
      { const: 'OS', prop: 'os' },
      { const: 'BROWSERS', prop: 'browser' },
      { const: 'DEVICES', prop: 'device' },
      { const: 'OS_VERSIONS', prop: 'os_version' }
    ];

    mappings.forEach(mapping => {
      this[mapping.prop] = Object.keys(Constants[mapping.const]).reduce((o: any, item: any) => {
        o[Constants[mapping.const][item]] = this.test(ua, Constants[`${mapping.const}_RE`][item]);
        return o;
      }, {});
    });

    mappings.forEach(mapping => {
      this[mapping.prop] = Object.keys(Constants[mapping.const])
        .map(key => {
          return Constants[mapping.const][key];
        })
        .reduce((previousValue, currentValue) => {
          return (previousValue === Constants[mapping.const].UNKNOWN && this[mapping.prop][currentValue])
            ? currentValue : previousValue;
        }, Constants[mapping.const].UNKNOWN);
    });

    this.browserVersion = '0';
    if (this.browser !== Constants.BROWSERS.UNKNOWN) {
      const re = Constants.BROWSER_VERSIONS_RE[this.browser];
      const res = this.exec(ua, re);
      if (!!res) {
        this.browserVersion = res[1];
      }
    }

    this.setPublicIp();
    this.setPrivateIp();
  }

  public isMobile(): boolean {
    return [
      Constants.DEVICES.ANDROID,
      Constants.DEVICES.IPHONE,
      Constants.DEVICES.I_POD,
      Constants.DEVICES.BLACKBERRY,
      Constants.DEVICES.FIREFOX_OS,
      Constants.DEVICES.WINDOWS_PHONE,
      Constants.DEVICES.VITA
    ].some(item => {
      return this.device === item;
    });
  }

  public isTablet(): boolean {
    return [
      Constants.DEVICES.I_PAD,
      Constants.DEVICES.FIREFOX_OS
    ].some(item => {
      return this.device === item;
    });
  }

  public isDesktop(): boolean {
    return [
      Constants.DEVICES.PS4,
      Constants.DEVICES.CHROME_BOOK,
      Constants.DEVICES.UNKNOWN
    ].some(item => {
      return this.device === item;
    });
  }

  private test(string: string, regex: any): any {
    const self = this;
    if (typeof regex === 'string') {
      regex = new RegExp(regex);
    }

    if (regex instanceof RegExp) {
      return regex.test(string);
    } else if (regex && Array.isArray(regex.and)) {
      return regex.and.every(function (item: any) {
        return self.test(string, item);
      });
    } else if (regex && Array.isArray(regex.or)) {
      return regex.or.some(function (item: any) {
        return self.test(string, item);
      });
    } else if (regex && regex.not) {
      return !self.test(string, regex.not);
    } else {
      return false;
    }
  }

  private exec(string: string, regex: any): any {
    const self = this;
    if (typeof regex === 'string') {
      regex = new RegExp(regex);
    }

    if (regex instanceof RegExp) {
      return regex.exec(string);
    } else if (regex && Array.isArray(regex)) {
      return regex.reduce(function (res: any, item: any) {
        return (!!res) ? res : self.exec(string, item);
      }, null);
    } else {
      return null;
    }
  }

  private setPrivateIp() {
    this.privateIp = '0.0.0.0';
  }

  private setPublicIp() {
    if (this.publicIp === '0.0.0.0') {
      // http://ipv4.myexternalip.com/json
      // https://api.ipify.org/?format=json
      // http://jsonip.com/
      this.http.get('https://api.ipify.org/?format=json')
        .pipe(
          map((response: any) => {
            return response;
          }),
          catchError((error: Response) => {
            console.log('No se pudo obtener la IP publica');
            // return Observable.throwError(error);
            return throwError(error);
          })
        )
        .subscribe((response: any) => {
          this.publicIp = response.ip;
        });

    }
  }

}
