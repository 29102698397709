import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';
import { EnvironmentLoaderService } from './environment-loader.service';
import { ENVIRONMENT } from '../enums/index';

export const LAZY_ENVIRONMENT_CONFIG = new InjectionToken<EnvironmentConfigLiteral>('LAZY_ENVIRONMENT_CONFIG');

export interface EnvironmentConfigLiteral {

  env: ENVIRONMENT;

}

let nextId = 1;

@Injectable()
export class LazyEnvironmentLoaderService extends EnvironmentLoaderService {
  id = nextId++;
  protected _config: EnvironmentConfigLiteral;

  constructor(@Optional() @Inject(LAZY_ENVIRONMENT_CONFIG) config: any = null) {
    super();
    this._config = config || {};
    console.log('@Optional() @Inject(LAZY_ENVIRONMENT_CONFIG)', config);
  }

  load(): EnvironmentConfigLiteral {
    console.log('nextId: ', nextId);
    return this._config;
  }

}
