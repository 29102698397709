import { IHttpResponseBdB } from "bdb-common-collections";
import { Observable } from "rxjs";

export class ResponseModel implements IHttpResponseBdB {

  private _response: Observable<any>;

  constructor(response: Observable<any>) {
    this.setResponse(response);
  }

  getResponse(): Observable<any> {
    return this._response;
  }

  setResponse(response: Observable<any>) {
    this._response = response;
  }

}
