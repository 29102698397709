/**
 * @author Francisco Giraldo Tejeiro
 * @name EventCodesConstants
 * @description Enumeraciones con codigos de eventos a reportar por el servicio de Funnel
 */

export enum EVENT_CODES {
    EC000 = 'Llego a la pantalla',
    EC001 = 'Paso por la pantalla',
    EC002 = 'Salio por el boton del banner',
    EC003 = 'Salio por inactividad',

    EC100 = 'Request',
    EC101 = 'Response',

    EC999 = 'No type'
}

export enum EVENT_ERROR_CODES {
    INFO = 'info',
    ERROR = 'error',
    WARNING = 'warning',
    FATAL = 'fatal'
}

export enum CHANNEL Â {
    WEB = 'web',
    FMV = 'fmv'
}
