export class IconModel {
    src: string;
    width: number;
    height: number;
    type: string;
    class: string;

    constructor() {
        this.height = 48;
        this.width = 48;
        this.type = 'svg';
        this.class = 'gray';
    }

    public get transformPlainSVG(): string {
        const svg = this.src;
        return svg;
    }

    public get transformSVG(): string {
        const size = `width="${this.width}" height="${this.height}"`;
        const svg = `<svg xmlns="http://www.w3.org/2000/svg" ${size}>` +
            `<g>${this.src}</g>` +
            `</svg>`;

        return `<div class="${this.class}-color-svg-icon">${svg}</div>`;
    }

}
