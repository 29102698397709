import { Injectable } from '@angular/core';
import { EnvironmentLoaderService } from './environment-loader.service';
import { EnvironmentConfigLiteral } from './lazy-environment-loader.service';
import { EnvironmentService } from './environment.service';

@Injectable()
export class EnvironmentSetupService {

  private _instance;

  constructor(private _loader: EnvironmentLoaderService, private env: EnvironmentService) {
    this._instance = null;
  }

  private createInstance(): EnvironmentConfigLiteral {
    console.log('_loader', this._loader);
    console.log('EnvironmentSetupService (this.env)', this.env);
    this._instance = this._loader.load();
    return this._instance;
  }

  public getInstance(): EnvironmentConfigLiteral {
    if (this._instance == null) {
      this.createInstance();
    }

    return this._instance;
  }
  
}
