import { Injectable } from '@angular/core';
import { Dictionary, ENVIRONMENT, EnvironmentService } from 'bdb-common-collections';
import { ENDPOINT } from '../enums/endpoint';

@Injectable()
export class PropertiesHttpClientService {

  private _dic: Dictionary<Dictionary<string>>;

  constructor(private env: EnvironmentService) {
    this._dic = new Dictionary<Dictionary<string>>();
    this.initialize();
  }

  private initialize() {
    const tmpDic: Dictionary<string> = new Dictionary<string>();
    tmpDic.add(ENVIRONMENT.QA.toString(), 'https://ko39g42mf8.execute-api.us-east-1.amazonaws.com/v1/publish/');
    tmpDic.add(ENVIRONMENT.ST.toString(), 'https://ko39g42mf8.execute-api.us-east-1.amazonaws.com/v1/publish/');
    tmpDic.add(ENVIRONMENT.PROD.toString(), 'https://api.bancodebogota.co/event/publish/');
    this._dic.add(ENDPOINT.Funnel.toString(), tmpDic);
  }

  getEndpoint(service: ENDPOINT): string {
    let oReturn: string = null;

    if (this._dic.containsKey(service.toString())) {
      const tmpDic: Dictionary<string> = this._dic.item(service.toString());
      const environment = this.env.environment;

      console.log('Funnel Environment:', environment);
      if (tmpDic.containsKey(environment)) {
        oReturn = tmpDic.item(environment);
      }
    }

    return oReturn;
  }

}
