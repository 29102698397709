import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';

import { IHttpClientBdB, IHttpResponseBdB, RequestModel, Dictionary, EventFunnelModel, EVENT_CODES, EVENT_ERROR_CODES, ProductFunnelModel } from 'bdb-common-collections';

import { FunnelService } from './funnel.service';
import { ResponseModel } from '../models';

@Injectable()
export class RequestService implements IHttpClientBdB {

  protected _headers: HttpHeaders;

  constructor(private http: HttpClient, private funnelService: FunnelService) { }

  public set Headers(value: Dictionary<string>) {
    this._headers = this.createHeaders(value);
  }

  private createHeaders(dic: Dictionary<string>): HttpHeaders {
    const h: any = {};

    dic.keys().forEach(key => {
      h[key] = dic.item(key);
    });

    return new HttpHeaders(h);
  }

  requestPost(request: RequestModel): IHttpResponseBdB {
    const oConfig = this.commonConfig(request);

    const oRequest = this.http.post(request.url, oConfig.body, oConfig.options)
      .pipe(
        timeout(oConfig.timeout),
        map(response => this.extractData(response, request.funnelData)),
        catchError(error => this.handleError(error, request.funnelData))
      );

    return new ResponseModel(oRequest);
  }

  requestGet(request: RequestModel): IHttpResponseBdB {
    const info = request.info;
    const oConfig = this.commonConfig(request);

    // let sUrl = request.url;
    const params = this.listParams(info);
    if (params !== '') {
      request.url = `${request.url}?${params}`
    }

    const oRequest = this.http.get(`${request.url}`, oConfig.options)
      .pipe(
        timeout(oConfig.timeout),
        map(response => this.extractData(response, request.funnelData)),
        catchError((error: HttpErrorResponse) => this.handleError(error, request.funnelData))
      );

    return new ResponseModel(oRequest);
  }

  requestPut(request: RequestModel): IHttpResponseBdB {
    const oConfig = this.commonConfig(request);

    const oRequest = this.http.put(request.url, oConfig.body, oConfig.options)
      .pipe(
        timeout(oConfig.timeout),
        map(response => this.extractData(response, request.funnelData)),
        catchError((error) => this.handleError(error, request.funnelData))
      );

    return new ResponseModel(oRequest);
  }

  requestGetRetryWhen(request: RequestModel): IHttpResponseBdB {
    throw new Error("Method not implemented.");
  }

  getMappingFunnel(info: ProductFunnelModel): EventFunnelModel {
    const dataFunnel: EventFunnelModel = {
      accessToken: info.Token,
      browser: '',
      channel: info.Channel,
      client: info.IsCustomer,
      description: info.Desc,
      device: '',
      eventDate: new Date(),
      exitCode: info.ExitCode,
      httpStatus: '',
      idNumber: info.IdNumber,
      journey: info.Journey,
      level: info.LevelLog,
      milestone: info.Milestone,
      privateIp: '',
      productId: info.Product.toString(),
      publicIp: '',
      step: info.Step,
      params: ''
    };

    return dataFunnel;
  }

  private commonConfig(request: RequestModel): { body: string, options: any, timeout: number } {
    let opts;
    let nTimeout = 31000;

    if (request.headers) {
      opts = { headers: this.createHeaders(request.headers) };
    } else {
      const dicHeaders: Dictionary<string> = new Dictionary<string>();
      dicHeaders.add('Accept', 'application/json');
      dicHeaders.add('Content-Type', 'application/json');
      this.Headers = dicHeaders;

      opts = { headers: this._headers };
    }
    opts['observe'] = 'response';

    if (request.timeout) {
      nTimeout = request.timeout;
    }


    // Se envia el registro de consumo funnel
    if (request.funnelData) {
      request.funnelData.description = request.url;
      request.funnelData.params = (request.info);
      request.funnelData.exitCode = EVENT_CODES.EC100;
      this.funnelService.sendFunnelEvent(request.funnelData);
    }

    return {
      body: JSON.stringify(request.info),
      options: opts,
      timeout: nTimeout
    }
  }

  private extractData(response, funnelData: EventFunnelModel) {
    if (funnelData) {
      funnelData.exitCode = EVENT_CODES.EC101;
      funnelData.eventDate = new Date();
      funnelData.httpStatus = response.status.toString();
      funnelData.params = JSON.stringify(response.body);
      this.funnelService.sendFunnelEvent(funnelData);
    }

    return response.body;
  }

  private handleError(error, funnelData: EventFunnelModel): Observable<any> {
    if (funnelData) {
      funnelData.level = EVENT_ERROR_CODES.ERROR;
      funnelData.exitCode = EVENT_CODES.EC101;
      funnelData.eventDate = new Date();
      funnelData.httpStatus = error.status.toString();
      funnelData.params = JSON.stringify(error);
      this.funnelService.sendFunnelEvent(funnelData);
    }

    return Observable.create(observer => {
      observer.next(error);
      observer.complete();
    });
  }

  private listParams(data): string {
    if (data === undefined) {
      return '';
    } else if (data === null) {
      return '';
    } else if (data === '') {
      return '';
    } else {
      return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
    }
  }

}
