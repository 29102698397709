import { IconModel } from '../models';
import { ImageFactory } from './image-factory';
import { ICON } from '../enums';
// @dynamic
export class IconFactory {

  constructor() { }


  public static get default(): IconModel {
    const icon: IconModel = new IconModel();

    icon.type = 'svg';
    icon.height = 36;
    icon.width = 36;
    icon.class = 'gray';

    return icon;
  }

  public static getIcon(iconType: ICON): IconModel {
    const icon: IconModel = IconFactory.default;
    icon.src = ImageFactory.getSource(iconType);
    return icon;
  }

}
