export enum ICON {

    flexiAhorroIcon = 'flexiAhorroIcon',
    libreAhorroIcon = 'libreAhorroIcon',
    uncheckedAcsMobile = 'uncheckedAcsMobile',
    checkedAcsMobile = 'checkedAcsMobile',
    uncheckedAcs = 'uncheckedAcs',
    checkedAcs = 'checkedAcs',
    libreAhorroIconAcsMobile = 'libreAhorroIconAcsMobile',
    flexiAhorroIconAcsMobile = 'flexiAhorroIconAcsMobile',
    libreAhorroIconAcs = 'libreAhorroIconAcs',
    flexiAhorroIconAcs = 'flexiAhorroIconAcs',
    uncheckedDeliveryWayMobile = 'uncheckedDeliveryWayMobile',
    checkedDeliveryWayMobile = 'checkedDeliveryWayMobile',
    homeDeliveryWayMobile = 'homeDeliveryWayMobile',
    officeDeliveryWayMobile = 'officeDeliveryWayMobile',
    uncheckedDeliveryWay = 'uncheckedDeliveryWay',
    checkedDeliveryWay = 'checkedDeliveryWay',
    homeDeliveryWay = 'homeDeliveryWay',
    officeDeliveryWay = 'officeDeliveryWay',
    scrollDownButton = 'scrollDownButton',
    alert = 'alert',
    success = 'success',
    back = 'back',
    close = 'close',
    logoBdBLarge = 'logoBdBLarge',
    logoBdBsmall = 'logoBdBsmall'
}
